export default [
  {
    path: '/user/dashboard',
    name: 'user-dashboard',
    component: () => import('../views/pages/user/UserDashboard.vue'),
    meta: {
      layout: 'vertical',
      resource: 'Auth',
      action: 'read',
      allowedRoles: ['user'],
    },
  },
  {
    path: '/user/subscription',
    name: 'user-subscription',
    component: () => import('../views/pages/user/UserSubscription.vue'),
    meta: {
      layout: 'vertical',
      resource: 'Auth',
      action: 'read',
      allowedRoles: ['user'],
    },
  },
  {
    path: '/user/discount',
    name: 'user-discount-code',
    component: () => import('../views/pages/user/discountCode.vue'),
    meta: {
      layout: 'vertical',
      resource: 'Auth',
      action: 'read',
      allowedRoles: ['user'],
    },
  },
  {
    path: '/user/referrals',
    name: 'user-referrals',
    component: () => import('../views/pages/user/UserReferrals.vue'),
    meta: {
      layout: 'vertical',
      resource: 'Auth',
      action: 'read',
      allowedRoles: ['user'],
    },
  },
  {
    path: '/user/settings',
    name: 'user-settings',
    component: () => import('../views/pages/user/Settings.vue'),
    meta: {
      layout: 'vertical',
      resource: 'User',
      action: 'read',
      allowedRoles: ['user'],
    },
  },
  // {
  //   path: '/user/billing',
  //   name: 'user-billing',
  //   component: () => import('../views/pages/user/Billing.vue'),
  //   meta: {
  //     layout: 'vertical',
  //     resource: 'User',
  //     action: 'read',
  //     allowedRoles: ['user'],
  //   },
  // },
  {
    path: '/user/support',
    name: 'user-support',
    component: () => import('../views/pages/user/Support.vue'),
    meta: {
      layout: 'vertical',
      resource: 'User',
      action: 'read',
      allowedRoles: ['user'],
    },
  },
];
