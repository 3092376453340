import Vue from 'vue';
import Keycloak from 'keycloak-js';
import constants from '@/constants';
const initOptions = {
  url: constants.KEYCLOAK_URL,
  realm: 'LiquiCharts',
  clientId: 'vue-liquicharts-client',
  onLoad: 'login-required',
};

const _keycloak = new Keycloak(initOptions);

const Plugin = {
  install: (Vue) => {
    Vue.$keycloak = _keycloak;
  },
};
Plugin.install = (Vue) => {
  Vue.$keycloak = _keycloak;
  Object.defineProperties(Vue.prototype, {
    $keycloak: {
      get() {
        return _keycloak;
      },
    },
  });
};

Vue.use(Plugin);

export default Plugin;
