export default [
  {
    path: '/admin/dashboard',
    name: 'admin-dashboard',
    component: () => import('../views/pages/admin/AdminDashboard.vue'),
    meta: {
      layout: 'vertical',
      resource: 'Auth',
      action: 'read',
      allowedRoles: ['admin'],
    },
  },
  {
    path: '/admin/users',
    name: 'admin-users',
    component: () => import('../views/pages/admin/Users.vue'),
    meta: {
      layout: 'vertical',
      resource: 'Auth',
      action: 'read',
      allowedRoles: ['admin'],
    },
  },
];
