import Vue from 'vue';
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue';
import VueCompositionAPI from '@vue/composition-api';
import VueClipboard from 'vue-clipboard2';
import VueSweetalert2 from 'vue-sweetalert2';
import fetchIntercept from 'fetch-intercept';

import router from './router';
import store from './store';
import App from './App.vue';

// Global Components
import './global-components';

// 3rd party plugins
import '@axios';
import '@/libs/portal-vue';
import '@/libs/toastification';

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

// Composition API
Vue.use(VueCompositionAPI);

// sweet alert
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2);

// clipboard
VueClipboard.config.autoSetContainer = true; // add this line
Vue.use(VueClipboard);

// import core styles
require('@core/scss/core.scss');

// import assets styles
require('@/assets/scss/style.scss');

Vue.config.productionTip = false;

import '@/plugins/keycloak';
// FIXME: ADD VUE LOGGING Env dependent CHECK
// console.log('Environment', process.env.NODE_ENV);
export const eventBus = new Vue();
Vue.$keycloak
  .init({ onLoad: 'login-required' })
  .then((auth) => {
    if (!auth) {
      window.location.reload();
    } else {
      new Vue({
        router,
        store,
        render: (h) => h(App),
      }).$mount('#app');
    }

    //Token Refresh
    setInterval(() => {
      Vue.$keycloak
        .updateToken(70)
        .then((refreshed) => {})
        .catch(() => {});
    }, 60000);
  })
  .catch((err) => {
    console.log('Authenticated Failed', err);
  });

fetchIntercept.register({
  request: function (url, config) {
    const token = Vue.$keycloak.token;
    config = {
      ...config,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    return [url, config];
  },

  requestError: function (error) {
    // Called when an error occured during another 'request' interceptor call
    return Promise.reject(error);
  },

  response: async function (response) {
    if (response.status === 401) {
      const res = await response.json();
      if ((res.s = 'old-session')) {
        eventBus.$emit('session-expired');
      }
    }
    return response;
  },

  responseError: function (error) {
    // Handle an fetch error
    console.log('error:', error);
    return Promise.reject(error);
  },
});
