export default [
  {
    path: '/broker/dashboard',
    name: 'broker-dashboard',
    component: () => import('../views/pages/broker/BrokerDashboard.vue'),
    meta: {
      layout: 'vertical',
      resource: 'Auth',
      action: 'read',
      allowedRoles: ['broker'],
    },
  },
  {
    path: '/broker/users',
    name: 'broker-users',
    component: () => import('../views/pages/broker/BrokerUsers.vue'),
    meta: {
      layout: 'vertical',
      resource: 'Auth',
      action: 'read',
      allowedRoles: ['broker'],
    },
  },
  {
    path: '/broker/transactions',
    name: 'broker-transactions',
    component: () => import('../views/pages/broker/BrokerTransactions.vue'),
    meta: {
      layout: 'vertical',
      resource: 'Auth',
      action: 'read',
      allowedRoles: ['broker'],
    },
  },
];
