import axios from '@axios';
import { API_URL } from '../constants';
import Vue from 'vue';
export const getUserBalance = async () => {
  const res = await axios.get(API_URL + '/users/balance');
  return res.data;
};

export const getUserRole = () => {
  // return 'user';
  // return 'broker';
  // return 'admin';
  const roles = Vue.$keycloak?.tokenParsed?.realm_access?.roles ?? [];
  if (roles.includes('user')) {
    return 'user';
  } else if (roles.includes('broker')) {
    return 'broker';
  } else if (roles.includes('admin')) {
    return 'admin';
  }
};
export const getUserInfo = async () => {
  const res = await axios.get(API_URL + '/users/info');
  return res?.data;
};

export const getUserSubscription = async () => {
  const userInfo = await getUserInfo();
  return userInfo?.subscription ?? {};
};

export const subscribe = async (subscriptionData) => {
  const res = await axios.post(API_URL + '/users/subscribe', subscriptionData);
  return res;
};

export const checkDiscountCode = async (code) => {
  const reqObj = {
    code,
  };
  const res = await axios.post(API_URL + '/users/discountCode', reqObj);
  return res;
};

export const getTransactions = async () => {
  const res = await axios.get(API_URL + '/payment/list');
  for (const trx of res.data) {
    trx.amountUSD = `$ ${trx.amountUSD.toFixed(2)}`;
  }
  return res.data;
};

export const requestPayment = async (paymentData) => {
  const { depositAmount, selectedDepositMethod, selectedDepositCurrency } =
    paymentData;
  const requestData = {
    price_amount: depositAmount,
    price_currency: 'usd',
    deposit_method: selectedDepositMethod,
    pay_currency: selectedDepositCurrency,
  };
  const res = await axios.post(API_URL + '/payment/request', requestData);
  return res.data;
};

export const getAvailableCurrencies = async () => {
  const res = await axios.get(API_URL + '/payment/currencies');
  return res.data;
};

export const getSubscriptionPlans = async () => {
  try {
    const res = await axios.get(API_URL + '/stripe/list-products');
    const plans = res?.data?.products;
    const canTrial = res?.data?.trial;

    plans.forEach((plan) => {
      plan.showLoadingButton = false;
      if (plan.metaData?.symbols) {
        plan.metaData.symbols = plan.metaData.symbols.split(',');
      }
      if (plan.metaData?.timeFrames) {
        plan.metaData.timeFrames = plan.metaData.timeFrames.split(',');
      }
      plan?.prices?.forEach((price) => {
        price.currency = price.currency.toUpperCase();
      });
    });

    plans.sort((a, b) => {
      const priceA = a.prices[0].amount;
      const priceB = b.prices[0].amount;
      return priceA - priceB;
    });
    return { plans, canTrial };
  } catch (error) {
    return [];
  }
};

export const createCheckoutSession = async (lookupKey) => {
  const res = await axios.post(
    API_URL + `/stripe/create-checkout-session?lookup_key=${lookupKey}`
  );
  return res.data;
};

export const getManageBillingUrl = async () => {
  const res = await axios.post(API_URL + '/stripe/create-portal-session');
  return res.data;
};
