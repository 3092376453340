import Vue from 'vue';
import VueRouter from 'vue-router';
import broker from './broker';
import user from './user';
import admin from './admin';

import * as tVanApi from '@/service/tVanApi';

Vue.use(VueRouter);
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/dashboard',
      alias: '/',
      name: 'dashboard',
      component: () => import('@/views/pages/Dashboard.vue'),
      meta: {
        allowedRoles: ['user', 'broker', 'admin'],
      },
    },
    {
      path: '/chart',
      name: 'trading-view',
      component: () => import('@/components/TVChartContainer.vue'),
      meta: {
        layout: 'full',
        allowedRoles: ['user', 'broker', 'admin'],
      },
    },
    ...admin,
    ...user,
    ...broker,
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('../views/pages/error/Error404.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        action: 'read',
        allowedRoles: ['user', 'broker', 'admin'],
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
});

router.beforeEach((to, from, next) => {
  const role = tVanApi.getUserRole();
  if (to.meta.allowedRoles) {
    if (!to.meta.allowedRoles.includes(role)) {
      //should be unauthorized ?
      return next('/error-404');
    } else if (to.meta.allowedRoles.includes(role)) {
      if (to.name === 'dashboard') {
        if (role === 'admin') {
          return next('/admin/dashboard');
        } else if (role === 'broker') {
          return next('/broker/dashboard');
        } else if (role === 'user') {
          return next('/user/dashboard');
        }
      } else {
        return next();
      }
    }
  }
  // else if !to.meta.allowedRoles -> report error
  else if (!to.meta.allowedRoles) {
    console.error(`Route ${to.name} is missing meta.allowedRoles`);
    return next('/error-404');
  }
});
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg');
  if (appLoading) {
    appLoading.style.display = 'none';
  }
});

export default router;
